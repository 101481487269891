import { discord_logo } from "assets";
import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { FaDiscord, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";

const SocialMedia = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [current, setCurrent] = useState("Orange");
  const [bottomPosition, setBottomPosition] = useState(20);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.pageYOffset;
      const pageHeight = document.documentElement.offsetHeight;
      const distanceFromBottom = pageHeight - scrollPosition;
      if (distanceFromBottom < 150) {
        setBottomPosition(150);
      } else {
        setBottomPosition(20);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const mediaLinks = [
    {
      id: 1,
      title: "Discord",
      icon: (
        <FaDiscord
          size={medium ? 24 : 16}
          color={current === "Discord" ? "#FFFFFF" : "#5b5b5b"}
        />
      ),
      link: "https://discord.gg/hUgYHqcjHT",
    },
    {
      id: 2,
      title: "Instagram",
      icon: (
        <RiInstagramFill
          size={medium ? 24 : 16}
          color={current === "Instagram" ? "#FFFFFF" : "#5b5b5b"}
        />
      ),
      link: "https://www.instagram.com/dominatetheskiesgame/",
    },
    {
      id: 3,
      title: "YouTube",
      icon: (
        <FaYoutube
          size={medium ? 24 : 16}
          color={current === "YouTube" ? "#FFFFFF" : "#5b5b5b"}
        />
      ),
      link: "https://www.youtube.com/@dominatetheskiesgame",
    },
    {
      id: 4,
      title: "X",
      icon: (
        <FaXTwitter
          size={medium ? 24 : 16}
          color={current === "X" ? "#FFFFFF" : "#5b5b5b"}
        />
      ),
      link: "https://x.com/DominateSkies",
    },
  ];

  return (
    <Container
      fluid
      className={`d-flex justify-content-center ${
        medium ? "flex-column" : ""
      } position-fixed`}
      style={
        medium
          ? { left: 30, zIndex: 2, height: "100vh", top: 0, width: "auto" }
          : { bottom: `${bottomPosition}px`, zIndex: 1 }
      }
    >
      {mediaLinks.map((mediaLink, index) => {
        return (
          <div className="d-flex">
            <a
              href={mediaLink.link}
              target="_blank"
              rel="noopener noreferrer"
              className="social-link mb-md-5 mx-md-0 mx-3"
              onMouseEnter={() => setCurrent(mediaLink.title)}
              onMouseLeave={() => setCurrent("Orange")}
            >
              {index === 0 ? (
                current === "Orange" ? (
                  <div
                    style={{
                      width: medium ? "24" : "16",
                      height: medium ? "24" : "16",
                    }}
                  >
                    <Image
                      src={discord_logo}
                      width={medium ? "24" : "16"}
                      height={medium ? "18" : "12"}
                      className=""
                      alt="Discord logo"
                    />
                  </div>
                ) : (
                  mediaLink.icon
                )
              ) : (
                mediaLink.icon
              )}
            </a>
            {medium && current === mediaLink.title && (
              <span className="ms-2 text-white">{mediaLink.title}</span>
            )}
          </div>
        );
      })}
    </Container>
  );
};

export default SocialMedia;
