import React, { useEffect, useState } from "react";
// import HomeVideoContainer from "components/Home/HomeVideoContainer";
import ContactUsForm from "components/Home/ContactUsForm";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import SocialMedia from "components/SocialMedia";
import { useMediaQuery } from "react-responsive";
import {
  dominate_the_sky,
  contact_us_bg_drop,
  spaceship_home_section_2,
  game_overview_vector,
  blue_squirrel_studios,
  footer_home_bg,
  spaceship_home_section_2_colored,
  game_overview_mobile,
  wishlist_vector,
  footer_home_bg_mobile,
  game_overview_mobile_colored,
  actual_blue_squirrel_studios,
  blue_squirrel_studios_old,
  blue_squirrel_logo,
  dts_logo,
} from "assets";
import SiteNavbar from "components/Navbar/Navbar";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";
import HomeVideoContainer from "components/Home/HomeVideoContainer";

const Home = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [showVideo, setShowVideo] = useState(false);
  const [gameOverviewImgSrc, setGameOverviewImgSrc] = useState(
    spaceship_home_section_2
  );
  const [isHovered, setIsHovered] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverOne, setHoverOne] = useState(false);
  const [hoverTwo, setHoverTwo] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const overviewData = [
    {
      id: 1,
      title: "Immersive Racing",
      description:
        "Navigate through stunning VR environments, including urban landscapes, forests, and futuristic circuits.",
    },
    {
      id: 2,
      title: "Assault Mode",
      description:
        "Equip your drone with offensive and defensive capabilities to outsmart and destroy your opponents while racing.",
    },
    {
      id: 3,
      title: "Multiplayer Mode",
      description:
        "Battle against friends or online opponents in real-time competitions.",
    },
    {
      id: 4,
      title: "Customization",
      description:
        "Upgrade your drones with advanced parts, modify them for maximum performance, and choose between speed or firepower to dominate the skies.",
    },
  ];

  // useEffect(() => {
  //   if (buttonClicked) {
  //     const timer = setTimeout(() => {
  //       setButtonClicked(false);
  //     }, 1000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [buttonClicked]);

  return (
    <Container
      style={{
        background: "linear-gradient(90deg, #000000 0%, #000000 100%)",
      }}
      className="position-relative d-flex flex-column justify-content-center align-items-center overflow-hidden px-0"
      fluid
    >
      <SocialMedia />
      <Container fluid className="px-0">
        <SiteNavbar />
      </Container>

      <Container
        fluid
        className={`d-flex ${
          large
            ? "justify-content-center align-items-start px-0"
            : "flex-column mt-5 pt-5"
        }`}
        style={{ minHeight: medium ? "90vh" : "80vh" }}
      >
        <Row>
          <Col lg="6">
            <div className="smallest-text user-select-none text-black">
              Dominate the Skies is a groundbreaking VR game that combines drone
              racing with tactical assault. Compete in high-speed races or
              engage in thrilling combat across stunning virtual environments.
              As the first VR game of its kind, it offers a unique gaming
              experience that immerses you in adrenaline-pumping drone warfare.
              Join the revolution and be part of this innovative gaming
              community.
            </div>
          </Col>
          <Col lg="6">
            <div className="d-flex flex-column justify-content-center">
              <div>
                <Image
                  src={dominate_the_sky}
                  alt="Space Ship Home Image"
                  style={{
                    width: "110%",
                    height: "auto",
                    WebkitMaskImage:
                      "radial-gradient(ellipse at center, white 40%, transparent 70%)",
                    maskImage:
                      "radial-gradient(ellipse at center, white 40%, transparent 80%)",
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center position-relative">
              <div
                className="px-5 py-1 py-lg-4 my-4 text-center text-white"
                style={{
                  width: !medium ? "187px" : "350px",
                  lineHeight: "28px",
                  letterSpacing: "1.5px",
                  border: "none",
                  borderRadius: 0,
                  background:
                    "linear-gradient(90deg, #1f1715 0%, #272727 100%)",
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                // onClick={() => setButtonClicked(true)}
                onClick={() => {
                  setShowVideo(true);
                  setButtonClicked(true);
                }}
              >
                {hover && (
                  <div
                    className={`position-absolute cursor-pointer ${buttonClicked ? "button-clicked-interaction" : "button-hover-interaction"}`}
                    style={{
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: buttonClicked ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.3)",
                    }}
                  ></div>
                )}
                <span
                  className="medium-text"
                  style={{
                    textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
                  }}
                >
                  Watch Now
                  {/* fun loading... */}
                </span>
              </div>
              {showVideo && (
                <div
                  className="w-100 position-fixed"
                  style={{
                    top: 0,
                    left: 0,
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <HomeVideoContainer setShowVideo={setShowVideo} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="px-0 position-relative d-flex justify-content-center align-items-center"
        style={{
          background: !medium
            ? "black"
            : "linear-gradient(to right, #1d1d1d 0%, #000000 30%, #313131 100%)",
          minHeight: medium ? "100vh" : "50vh",
        }}
      >
        <Container className="text-white px-0">
          <Row>
            <Col lg={6}>
              <h1
                className="big-text mb-5"
                style={{
                  fontWeight: "bold",
                  marginLeft: !medium ? "24px" : "",
                }}
              >
                GAME OVERVIEW
              </h1>
              {medium && (
                <div className="d-flex align-items-center mb-4">
                  <h2 className="small-text exo-2 font-weight-500 mb-3">
                    Redefining VR Gaming
                  </h2>
                  <Image
                    className="img-fluid"
                    src={game_overview_vector}
                    alt="Game Overview"
                  />
                </div>
              )}
              {!medium && (
                <Image
                  src={
                    isClicked
                      ? game_overview_mobile_colored
                      : game_overview_mobile
                  }
                  className="img-fluid"
                  onClick={() => setIsClicked(!isClicked)}
                  // style={{maxWidth:'401px',maxHeight:'284px'}}
                />
              )}
              <div className="d-flex flex-column small-text exo-2 text-light-gray mx-4 mx-md-0">
                <span>
                  Experience next-level gameplay with Dominate the Skies, where
                  VR anti-gravity racing collides with intense aerial combat.
                  Navigate{" "}
                  <a
                    href="/news"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className="small-text exo-2 text-light-gray"
                  >
                    challenging environments
                  </a>{" "}
                  at breakneck speed or engage in strategic dogfights using
                  advanced anti-gravity technology. This dual-mode VR game
                  offers relentless excitement, immersing you in the
                  cutting-edge world of virtual reality.
                </span>
                <span className="mt-2">What you'll get:</span>
                <ul>
                  {overviewData.map((data) => {
                    return <li>{data.title}</li>;
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="position-absolute" style={{ right: 0, bottom: 0 }}>
          {medium && (
            <Image
              src={gameOverviewImgSrc}
              alt="spacecraft"
              style={{ height: "80vh" }}
              onMouseEnter={() =>
                setGameOverviewImgSrc(spaceship_home_section_2_colored)
              }
              onMouseLeave={() =>
                setGameOverviewImgSrc(spaceship_home_section_2)
              }
              onCl
            />
          )}
        </div>
      </Container>
      <Container
        fluid
        className={`px-0 d-flex justify-content-center align-items-center text-white ${
          medium ? "contact-us-bg" : ""
        }`}
        style={{
          minHeight: medium ? "100vh" : "100vh",
          position: "relative",
          zIndex: 0,
        }}
      >
        {/* <div
          className={`d-flex ${
            !medium
              ? "flex-column align-items-center justify-content-center"
              : "justify-content-center align-items-center"
          }`}
          style={{
            flex: 1,
            position: "relative",
            zIndex: 1,
          }}
        > */}
        <Container className="px-0 mx-4 mx-md-0">
          <div
            style={{
              // maxWidth: medium ? "800px" : "580px",
              textAlign: !medium ? "center" : "left",
            }}
          >
            <div
              className="d-flex"
              style={{ justifyContent: !medium ? "center" : "" }}
            >
              <h1
                className="big-text"
                style={{ fontWeight: "bold", zIndex: 1111 }}
              >
                CONTACT US
              </h1>
              <img
                src={wishlist_vector}
                alt="Wishlist vector"
                style={{
                  height: "47px",
                  width: "47px",
                  transform: !medium
                    ? "translate(-35%, -18%)"
                    : "translate(-42%, 3%)",
                }}
              />
            </div>
            <div className="mt-4 mb-5 text-start small-text exo-2 text-light-gray">
              <span>
                We'd Love to Hear From You! Have questions, feedback, or just
                want to reach out?{" "}
              </span>
              <span>{`Drop your message below! :)`}</span>
            </div>
            <ContactUsForm />
          </div>
        </Container>
        {/* </div> */}

        {/* {medium && (
          <div className="d-flex" style={{ height: "100vh", zIndex: 0 }}>
            <img
              src={contact_us_bg_drop}
              alt="contact us bg drop"
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
              }}
            />
          </div>
        )} */}
      </Container>
      <Container
        fluid
        className={`text-white d-flex ${!medium ? "px-0" : "p-4"}`}
        style={{
          height: medium ? "30vh" : "130px",
          backgroundImage: `url(${
            medium ? footer_home_bg : footer_home_bg_mobile
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: medium ? "" : "cover",
          position: "relative",
          filter: isHovered || isClicked ? "none" : "grayscale(100%)",
          transition: "filter 0.3s ease-in-out",
        }}
        onMouseEnter={() => {
          if (medium) setIsHovered(true);
        }}
        onMouseLeave={() => {
          if (medium) setIsHovered(false);
        }}
        onClick={() => {
          if (!medium) setIsClicked(!isClicked);
        }}
      >
        <Container className="d-flex align-items-center justify-content-end">
          <div
            className={`d-flex flex-column ${
              medium ? "justify-content-center me-5" : "justify-content-end"
            }`}
            onMouseEnter={() => setHoverOne(true)}
            onMouseLeave={() => setHoverOne(false)}
          >
            {/* {hoverOne && (
              <div
                className="position-absolute"
                style={{
                  width: "146px",
                  height: "111px",
                  background: "rgb(0,0,0,0.5)", //
                  transform: "translate(0%,0%)",
                }}
              ></div>
            )} */}
            <a
              href="https://dominatetheskies.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={dts_logo}
                alt="Dominate the skies logo"
                fluid
                style={{
                  width: !medium ? "110px" : "177px",
                  height: !medium ? "55px" : "auto",
                }}
              />
            </a>
            {/* <div className="mt-4 navbar-header">BLUE SQUIRREL STUDIOS</div> */}
          </div>
          <div
            className={`d-flex flex-column ${
              medium
                ? "justify-content-center me-5"
                : "justify-content-end ms-2"
            }`}
            onMouseEnter={() => setHoverTwo(true)}
            onMouseLeave={() => setHoverTwo(false)}
          >
            <a
              href="https://bluesquirrelstudios.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={blue_squirrel_logo}
                alt="Blue Squirrel Studios Logo"
                fluid
                style={{
                  width: !medium ? "120px" : "250px",
                  height: "auto",
                }}
              />
            </a>
          </div>
        </Container>
      </Container>
      <Footer />
    </Container>
  );
};

export default Home;
