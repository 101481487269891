import {
  lorem_avtar_left,
  lorem_avtar_left_colored,
  lorem_avtar_mobile,
  lorem_card_img_1,
  lorem_right_header_graphic,
  lorem_mobile_vector,
  lorem_avtar_mobile_colored,
  lorem_right_header_vector,
  gallery_img_1,
  gallery_img_2, // Import the vector
} from "assets";
import Footer from "components/Footer";
import SiteNavbar from "components/Navbar/Navbar";
import SocialMedia from "components/SocialMedia";
import React, { useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Lorem = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const [leftAvtarSrc, setleftAvtarSrc] = useState(lorem_avtar_left);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const data = [
    {
      id: 1,
      image: `${gallery_img_1}`,
    },
    {
      id: 2,
      image: `${gallery_img_2}`,
    },
  ];

  return (
    <Container
      fluid
      className="px-0 text-white"
      style={{
        background:
          "linear-gradient(90deg, #1c1c1c 0%, #0e0e0e 30%, #313131 100%)",
        minHeight: "100vh",
      }}
    >
      <SocialMedia />
      <Container fluid className="px-0">
        <SiteNavbar />
      </Container>

      <div className="user-select-none text-center text-transparent position-absolute">
        Experience the thrill and beauty of Dominate the Skies in our Sky
        Gallery. From intense drone battles to breathtaking races, see why this
        game is capturing the attention of both gamers and tech and gaming
        investors.
      </div>
      {xlarge ? (
        <Container fluid className="px-0 d-flex text-white">
          <div className="d-flex justify-content-start">
            <Image
              src={leftAvtarSrc}
              alt="Main Figure"
              onMouseEnter={() => setleftAvtarSrc(lorem_avtar_left_colored)}
              onMouseLeave={() => setleftAvtarSrc(lorem_avtar_left)}
              style={{
                height: "90vh",
                left: 0,
                bottom: 50,
              }}
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ flex: 1 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  src={lorem_right_header_graphic}
                  alt="lorem_right_header_graphic"
                  className="img-fluid position-absolute px-0"
                  style={{
                    width: "810px",
                    right: 0,
                    top: 160,
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="position-relative">
                <h1
                  className="big-text position-absolute"
                  style={{
                    color: "white",
                    textShadow: "2px 1px 2px rgba(0, 0, 0, 0.7)",
                    zIndex: 3,
                    top: -100,
                    left: "30%",
                  }}
                >
                  Sky Gallery
                </h1>
                <img
                  src={lorem_right_header_vector}
                  className="position-absolute"
                  style={{
                    zIndex: "2",
                    top: -120,
                    left: -120,
                  }}
                />
              </div>
              {data.map((item, index) => (
                <div
                  className="text-center"
                  style={{ marginBottom: "20px", padding: "10px", zIndex: 1 }}
                  key={index}
                >
                  <img
                    src={item.image}
                    alt={`${item.id} Thumbnail`}
                    style={{
                      width: "348px",
                      height: "auto",
                      boxShadow: "-21px 28px 4px 0px #000000",
                      filter:
                        hoveredIndex === index ? "none" : "grayscale(100%)",
                      transition: "filter 0.2s ease-in-out",
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Container>
      ) : (
        <Container fluid className="px-0 d-flex flex-column text-white">
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <h1
                className="big-text"
                style={{
                  lineHeight: "55px",
                  color: "white",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
                  position: "abosolute",
                  marginLeft: "32px",
                  zIndex: 1,
                }}
              >
                Sky Gallery
              </h1>
              <Image src={lorem_mobile_vector} alt="Mobile Vector" />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Image
                onClick={() => setIsClicked(!isClicked)}
                src={
                  isClicked ? lorem_avtar_mobile_colored : lorem_avtar_mobile
                }
                alt="Armored Figure"
                style={{
                  maxWidth: "344px",
                  maxHeight: "308px",
                  zIndex: 2,
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mb-5">
            {data.map((item, index) => (
              <div
                className="text-center"
                style={{ marginBottom: "20px", padding: "10px", zIndex: 1 }}
                key={index}
              >
                <img
                  src={item.image}
                  alt={`${item.id} Thumbnail`}
                  style={{
                    width: "100%",
                    filter: hoveredIndex === index ? "none" : "grayscale(100%)",
                    transition: "filter 0.2s ease-in-out",
                  }}
                  onClick={() =>
                    setHoveredIndex(hoveredIndex === index ? null : index)
                  }
                />
              </div>
            ))}
          </div>
        </Container>
      )}
      <Container fluid className="px-0">
        <Footer />
      </Container>
    </Container>
  );
};

export default Lorem;
