import axios from "axios";
import { notify } from "utilities/notify";

export const contactUs = async (data) => {
  try {
    const response = await axios.post("https://gameapis.masterthesky.com/api/contact-us/", data);
    if (response.status === 201 || response.status === 200) {
      console.log("Contact Us Response: ", response.data);
      return response.data;
    }
  } catch (error) {
    console.log("Contact Us error", error);
    notify("There was an error submitting the form", "error");
  }
};

export const wishlist = async (data) => {
  try {
    const response = await axios.post("https://gameapis.masterthesky.com/api/wishlist/", data);
    if (response.status === 201 || response.status === 200) {
      console.log("Wishlist Response: ", response.data);
      return response.data;
    }
  } catch (error) {
    console.log("Wishlist error", error);
    notify("There was an error submitting the form", "error");
  }
};