import React from "react";
import "./App.css";
import "./App.scss";
import Routers from "Routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConf } from "utilities/notify";

const App = () => {
  return (
    // {<Container fluid className="fullscreen-poster text-center w-100">
    //   <div className="coming-soon-text text-center">COMING SOON</div>
    // </Container>}
    <>
      <ToastContainer {...toastConf} />
      <Routers />
    </>
  );
};

export default App;
