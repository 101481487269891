import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  blue_squirrel_studios,
  default_logo,
  dts_logo,
  line_md_menu_unfold_left,
  menu_back_icon,
} from "assets";
import { useMediaQuery } from "react-responsive";

const navRoutes = [
  { title: "News", path: "news" },
  { title: "Sky Gallery", path: "sky-gallery" },
  { title: "Press Kit", path: "press-kit" },
  { title: "Wishlist", path: "wishlist" },
];

const SiteNavbar = ({
  showLogo = true,
  showBackIcon,
  backAction,
  showToggleIcon = true,
  title,
  showTitleOnLg,
  className,
  style,
}) => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const offCanvasRef = useRef(null);
  // const params = location.pathname.split("/").filter((item) => item !== "");
  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleOffCanvasToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        offCanvasRef.current &&
        !offCanvasRef.current.contains(event.target) &&
        open
      ) {
        handleOffCanvasToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleOffCanvasToggle]);

  return (
    <Navbar
      expand={false}
      className={"bg-transparent offcanvas-bg px-4 py-md-2 user-select-none"}
      style={{ minHeight: 76, ...style }}
    >
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        <Navbar.Brand
          href="/"
          className="d-flex align-items-center justify-content-center cursor-pointer text-white"
          style={{ zIndex: 2 }}
        >
          {/* <img
            src={default_logo}
            alt="Master the Sky"
            className="img-fluid cursor-pointer me-3"
            style={{ objectFit: "contain", width: !medium ? "18px" : "38px" }}
          />
          <p
            className="mb-0 cursor-pointer navbar-header d-flex align-items-center"
            style={{
              // fontSize: !medium ? "8.55px" : "18px",
              textTransform: "uppercase",
            }}
          >
            dominate the skies
          </p> */}
          <img
            src={dts_logo}
            alt="Dominate the Skies"
            className="img-fluid cursor-pointer"
            style={{ objectFit: "contain", width: !medium ? "73px" : "259px", height: !medium ? "38px" : "132px"}}
          />
        </Navbar.Brand>

        {!open && showToggleIcon && (
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-false"
            className="focus-none border-0 p-1"
            onClick={handleOffCanvasToggle}
          >
            <img
              src={line_md_menu_unfold_left}
              alt="line_md_menu_unfold_left"
              className="focus-none img-fluid"
              style={{
                // width: "24px",
                // padding: "4px 0",
                filter: isHovered ? "brightness(100) saturate(0%)" : "none",
              }}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            />
          </Navbar.Toggle>
        )}

        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-false"
          aria-labelledby="offcanvasNavbarLabel-expand-false"
          placement="end"
          show={open}
          className="bg-transparent text-white"
          style={{
            width: 400,
            backgroundImage:
              "linear-gradient(to right, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1))",
          }}
        >
          <Offcanvas.Body>
            <div
              className="d-flex justify-content-center align-items-start"
              ref={offCanvasRef}
            >
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <div
                    onClick={handleOffCanvasToggle}
                    className="ms-auto text-center pt-2 mb-5 pb-5 cursor-pointer"
                  // style={{ width: 40, height: 100 }}
                  >
                    <img
                      src={menu_back_icon}
                      alt="close menu"
                      className="img-fluid"
                      style={{ width: 29, height: 24 }}
                    />
                  </div>
                </div>
                {navRoutes.map((route, i) => (
                  <a
                    key={i}
                    href={`/${route.path}`}
                    className="d-flex flex-column justify-content-center pb-2 text-decoration-none"
                    onMouseEnter={() => setHoveredIndex(i)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <div
                      className="small-text bebas-neue text-white mb-5 px-2 d-flex justify-content-center align-items-center"
                      style={{
                        width: "242px",
                        height: "42px",
                        fontSize: "18.32px",
                        textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
                        border:
                          hoveredIndex === i ? "1px solid #ECECEC" : "none",
                        borderRadius: "12px",
                      }}
                    >
                      {route.title}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default SiteNavbar;
