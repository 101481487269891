import { contact_us_bg_drop, wishlist_vector } from "assets";
import Footer from "components/Footer";
import SiteNavbar from "components/Navbar/Navbar";
import SocialMedia from "components/SocialMedia";
import WishlistForm from "components/Wishlist/WishlistForm";
import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Wishlist = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <Container
      className={`px-0 d-flex flex-column justify-content-center align-items-center ${medium ? "wishlist-bg" : "bg-black"
        }`}
      style={{
        // background:
        //   "linear-gradient(to right, #0b0b0b 10%, #131313 40%, #1b1b1b 100%)",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
      fluid
    >
      <SocialMedia />
      <Container
        fluid
        className="px-0"
        style={{ zIndex: 1, position: "relative" }}
      >
        <SiteNavbar />
      </Container>

      <Container
        className={`px-0 mx-4 mx-md-0 d-flex flex-column ${medium ? "justify-content-center align-items-start" : ""}`}
        style={{
          minHeight: "92vh",
          color: "#fff",
          // position: "relative",
        }}
      >
        <div
          className={`d-flex ${!medium ? "justify-content-center" : "justify-content-start"
            } align-items-start"
              }`}
        >
          <h1
            className="big-text"
            style={{ fontWeight: "bold", zIndex: 1 }}
          >
            Wishlist
          </h1>
          <img
            src={wishlist_vector}
            style={{
              height: "47px",
              width: "47px",
              transform: !medium
                ? "translate(-35%, -18%)"
                : "translate(-40%, 10%)",
            }}
          />
        </div>
        <div
          className={`${!medium ? "d-flex flex-column align-items-start mt-4 mb-5 mx-4" : "mt-5 mb-5"
            } small-text exo-2`}
        >
          <span>
            Ready to take flight? Add <strong>Dominate the Skies</strong> to
            your wishlist and be the first to experience the future of VR
            drone racing and combat.
          </span>
        </div>
        <div>
          <WishlistForm />
        </div>
        {/* {medium && (
          <div className="d-flex" style={{ height: "100vh", right: 0 }}>
            <img
              src={contact_us_bg_drop}
              alt="contact us bg drop"
              // className="img-fluid"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>
        )} */}
      </Container>
      <Footer />
    </Container>
  );
};

export default Wishlist;
