import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import * as actions from "actions/actions";
import { notify } from "utilities/notify";
import { validateEmail } from "utilities/validateEmail";

const ContactUsForm = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const form = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [hover, setHover] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    setIsSending(true);

    if (validateEmail(formData.get("email"))) {
      const data = {
        name: formData.get("name"),
        email: formData.get("email"),
        message: formData.get("message"),
      };
      const response = await actions.contactUs(data);
      if (response) {
        notify("Message received! We'll be in touch soon.", "success");
      }
    }
    setIsSending(false);

  };

  useEffect(() => {
    if (buttonClicked) {
      const timer = setTimeout(() => {
        setButtonClicked(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [buttonClicked]);

  return (
    <Form
      ref={form}
      onSubmit={onSubmit}
      className="d-flex flex-column align-items-stretch"
      style={{ width: medium ? "580px" : "" }}
    >
      <Form.Group
        className="mb-4 d-flex align-items-center justify-content-between"
        style={{ flexDirection: !medium ? "column" : "" }}
      >
        <Form.Label className="small-text exo-2 text-light-gray">
          Name
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          className="small-text text-white"
          style={{
            width: !medium ? "237.5px" : "380px",
            height: !medium ? "25px" : "40px",
            backgroundColor: "transparent",
            borderColor: "1px solid #ECECEC",
            borderRadius: !medium ? "9.38px" : "15px",
          }}
        />
      </Form.Group>

      <Form.Group
        className="mb-4 d-flex align-items-center justify-content-between"
        style={{ flexDirection: !medium ? "column" : "" }}
      >
        <Form.Label className="small-text exo-2 text-light-gray">
          Email
        </Form.Label>
        <Form.Control
          type="text"
          name="email"
          required
          className="small-text text-white"
          style={{
            width: !medium ? "237.5px" : "380px",
            height: !medium ? "25px" : "40px",
            backgroundColor: "transparent",
            borderColor: "1px solid #ECECEC",
            borderRadius: !medium ? "9.38px" : "15px",
          }}
        />
      </Form.Group>

      <Form.Group
        className="d-flex align-items-center justify-content-between"
        style={{ flexDirection: !medium ? "column" : "" }}
      >
        <Form.Label className="small-text exo-2 text-light-gray">
          Message
        </Form.Label>
        <Form.Control
          type="text"
          name="message"
          required
          className="small-text text-white"
          style={{
            width: !medium ? "237.5px" : "380px",
            height: !medium ? "25px" : "40px",
            backgroundColor: "transparent",
            borderColor: "1px solid #ECECEC",
            borderRadius: !medium ? "9.38px" : "15px",
          }}
        />
      </Form.Group>

      <div
        className={`d-flex ${!medium ? "justify-content-around" : "justify-content-end"} mt-5`}
      >
        <Button
          className="px-5 py-2 py-lg-3 position-relative"
          type="submit"
          disabled={isSending}
          style={{
            width: !medium ? "250px" : "314px",
            fontWeight: "bold",
            border: "none",
            borderRadius: 0,
            background: "linear-gradient(90deg, #1f1715 0%, #272727 100%)",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setButtonClicked(true)}
        >
          {hover && (
            <div
              className={`position-absolute cursor-pointer ${buttonClicked ? "button-clicked-interaction" : "button-hover-interaction"}`}
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: buttonClicked ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.3)",
              }}
            ></div>
          )}
          <span
            className="small-text bebas-neue"
            style={{
              textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
            }}
          >
            {isSending ? (
              <span>
                <Spinner size="sm" />
                <span className="ms-2">SENDING...</span>
              </span>
            ) : (
              <span>SEND</span>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default ContactUsForm;
