import Home from "pages/Home/Home";
import Media from "pages/Media/Media";
import News from "pages/News/News";
import Presskit from "pages/Presskit/Presskit";
import Wishlist from "pages/Wishlist/Wishlist";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="news" element={<News />} />
        <Route path="sky-gallery" element={<Media />} />
        <Route path="press-kit" element={<Presskit />} />
        <Route path="wishlist" element={<Wishlist />} />
      </Routes>
    </Router>
  );
};

export default Routers;
