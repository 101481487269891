import { news_2, news_coming_soon, news_vector_graphic } from "assets";
import Footer from "components/Footer";
import SiteNavbar from "components/Navbar/Navbar";
import SocialMedia from "components/SocialMedia";
import React, { useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const News = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const data = [
    {
      id: 1,
      image: `${news_2}`,
      title: "See you at IGDC 2024 – #Booth E60! 🚀",
      description: (
        <div className="d-flex flex-column">
          <span>
            Visit booth E60 on November 13th, 14th and 15th 2024 and play our
            exciting demo! Explore heart-racing tracks, pilot anti-gravity
            vehicles, and immerse yourself in a thrilling, futuristic racing
            experience. Don't miss the excitement!
          </span>
          <span className="mt-2">Stay tuned for more!</span>
        </div>
      ),
    },
    {
      id: 2,
      image: `${news_coming_soon}`,
      title: "Demo Drops: Futuristic Racing & Combat Game!",
      description: (
        <span>
          Experience the thrill of{" "}
          <a
            href="/"
            target="_blank"
            style={{ textDecoration: "none" }}
            className="small-text exo-2 text-light"
          >
            anti-gravity vehicles
          </a>{" "}
          and intense assaults! The upcoming demo features avatars, anti-gravity
          vehicles, and adrenaline-pumping race and assault tracks. Get ready
          for an action-packed, futuristic adventure!
        </span>
      ),
    },
  ];

  return (
    <Container
      fluid
      className="px-0 d-flex flex-column"
      style={{
        background:
          "linear-gradient(to right, #000000 10%, #202020 50%, #3f3f3f 100%)",
        minHeight: "100vh",
        overflowX: "hidden",
        padding: medium ? "0 16px" : "0 40px",
        position: "relative",
      }}
    >
      <SocialMedia />
      <Container fluid className="px-0">
        <SiteNavbar />
      </Container>

      <div
        className="d-flex align-items-center position-absolute"
        style={{ left: "0", top: medium ? "11.1%" : "7.9%" }}
      >
        <img
          src={news_vector_graphic}
          alt="news_vector_graphic"
          style={{ maxHeight: "55px", maxWidth: !medium ? "150px" : "556px" }}
        />
      </div>

      <Container
        fluid
        className="px-0 flex-grow-1 mt-lg-5 mt-4"
        style={{ minHeight: "90vh" }}
      >
        <div
          className="text-white mb-md-5 my-lg-1 big-text text-center"
          style={{ textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)" }}
        >
          news
        </div>

        <Container
          className={`d-flex flex-column ${!medium ? "align-items-center" : "align-items-start"
            } `}
        >
          {data.map((news, index) => (
            <div
              key={index}
              className={`d-flex align-items-start m-lg-5 my-5 mx-3 ${!medium ? "flex-column" : ""
                }`}
              style={{ height: !medium ? "auto" : "" }}
            >
              <img
                src={news.image}
                alt="Coming Soon"
                style={{
                  height: medium ? "250px" : "auto",
                  width: medium ? "auto" : "100%",
                  flexShrink: 0,
                  boxShadow: medium
                    ? "-18px 24px 2px rgba(0, 0, 0, 0.9)"
                    : "none",
                  filter: hoveredIndex === index ? "none" : "grayscale(100%)",
                  transition: "filter 0.2s ease-in-out",
                }}
                onMouseEnter={() => {
                  if (medium) setHoveredIndex(index);
                }}
                onMouseLeave={() => {
                  if (medium) setHoveredIndex(null);
                }}
                onClick={() => {
                  if (!medium)
                    setHoveredIndex(hoveredIndex === index ? null : index);
                }}
              />
              <div
                className={`d-flex flex-column justify-content-start align-items-start ${!medium ? "mt-5 px-3" : " ms-5"
                  }`}
              >
                <h4
                  className={`text-white mb-4 ${medium ? "small-text exo-2" : "big-text bebas-neue"
                    }`}
                  style={{ textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)" }}
                >
                  {news.title}
                </h4>
                <p className="text-light small-text exo-2">
                  {news.description}
                </p>
              </div>
            </div>
          ))}
        </Container>
      </Container>

      <Container fluid className="px-0">
        <Footer />
      </Container>
    </Container>
  );
};

export default News;
